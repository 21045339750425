import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import "moment-timezone";

declare var ol: any;

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.css']
})
export class TrackerComponent implements OnInit {

  timeFormat = 'hh:MM:ss A';
  currentTime = '';
  flights : any = {};
  stamp : any = {};
  proof = null;
  delay = 5;

  ol: any;
  map : any;
  layers : any = [];
  selectedCallSign = "";

  constructor(
    private httpClient: HttpClient,
    private sanitizer : DomSanitizer
  ) { }

  ngOnInit() {
    setInterval(() => {
      this.currentTime = moment().tz('America/New_York').format(this.timeFormat);
      this.delay--;
      if (this.delay == 0) {
        this.delay = 5;
        this.loadData();
      }
    }, 1000);
    this.loadData();

    this.map = new ol.Map({
      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        })
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([-84.37865, 33.789]),
        zoom: 10
      })
    });
    var me = this;
    this.map.on('click', function(evt) {

      me.map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
        console.log(feature.getProperties());
        me.selectedCallSign = feature.getProperties().data.callsign;
        document.getElementById(me.selectedCallSign).scrollIntoView();
        return feature;
      });
    });
  }

  addPoint(v) {
    var layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [new ol.Feature({
          data: v,
          geometry: new ol.geom.Point(ol.proj.transform([v.longitude, v.latitude], 'EPSG:4326', 'EPSG:3857')),
        })]
      }),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          rotation: v.heading * Math.PI / 180.0,
          src: "assets/images/plane.png"
        })
      })
    });
    this.map.addLayer(layer);
    this.layers.push(layer);
  }

  loadData() {
    var me = this;
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/track').subscribe((data)=>{

      me.layers.forEach(function(layer) { me.map.removeLayer(layer); });
      me.layers = [];

      this.flights = data;
      this.flights['vectors'].forEach(function(v) {
        me.addPoint(v);
      });
    });
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/track/stamp').subscribe((data)=>{
      me.stamp = data;
    });
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/track/proof', {responseType: 'blob'}).subscribe((data)=>{
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function() {
        var base64data : any = reader.result;
        me.proof = me.sanitizer.bypassSecurityTrustUrl(base64data);
      }
    });
  }

  format(time) {
    return moment.unix(time).fromNow();
  }

}
