import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrackerComponent } from './tracker/tracker.component';
import { IssComponent } from './iss/iss.component';

const routes: Routes = [
 { path: '', component: TrackerComponent },
 { path: 'iss', component: IssComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
