import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import "moment-timezone";

declare var ol: any;

@Component({
  selector: 'app-iss',
  templateUrl: './iss.component.html',
  styleUrls: ['./iss.component.css']
})
export class IssComponent implements OnInit {

  timeFormat = 'hh:MM:ss A';
  currentTime = '';
  stamp : any = {};
  position : any = {};

  proof = null;
  delay = 5;

  ol: any;
  map : any;
  view : any;
  layers : any = [];

  constructor(
    private httpClient: HttpClient,
    private sanitizer : DomSanitizer
  ) { }

  ngOnInit() {
    setInterval(() => {
      this.currentTime = moment().tz('GMT').format(this.timeFormat);
      this.delay--;
      if (this.delay == 0) {
        this.delay = 5;
        this.loadData();
      }
    }, 1000);
    this.loadData();

    this.view = new ol.View({
      center: ol.proj.fromLonLat([-84.37865, 33.789]),
      zoom: 2
    });
    this.map = new ol.Map({
      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        })
      ],
      view: this.view
    });
    this.addDayNightLayer();
  }

  loadData() {
    var me = this;
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/iss').subscribe((data)=>{

      me.layers.forEach(function(layer) { me.map.removeLayer(layer); });
      me.layers = [];

      me.addPoint(data);

      me.position = data;
      // re-center map
      me.map.getView().setCenter(ol.proj.transform([me.position.longitude, me.position.latitude], 'EPSG:4326', 'EPSG:3857'));
      me.map.getView().setZoom(2);
    });
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/iss/stamp').subscribe((data)=>{
      me.stamp = data;
    });
    this.httpClient.get('https://api.hashlog.io/public/data/stamping/iss/proof', {responseType: 'blob'}).subscribe((data)=>{
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function() {
        var base64data : any = reader.result;
        me.proof = me.sanitizer.bypassSecurityTrustUrl(base64data);
      }
    });
  }

  addPoint(iss) {
    var layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [new ol.Feature({
          data: iss,
          geometry: new ol.geom.Point(ol.proj.transform([iss.longitude, iss.latitude], 'EPSG:4326', 'EPSG:3857')),
        })]
      }),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          src: "assets/images/space-station.png"
        })
      })
    });
    this.map.addLayer(layer);
    this.layers.push(layer);
    this.addDayNightLayer();
  }

  addDayNightLayer() {
    var layer = new ol.layer.Vector({
      source: new ol.source.DayNight ({ time: new Date() }),
      opacity: .3,
      style: new ol.style.Style({
        fill: new ol.style.Fill({
          color: [0,0,0]
        })
      })
    });
    this.map.addLayer(layer);
    this.layers.push(layer);
  }
}
